import React from 'react'
import './possibility.css';

const Possibility = () => {
  return (
    <div>
      Possibility
    </div>
  )
}

export default Possibility