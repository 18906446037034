import React from 'react'
import Feature from '../../components/feature/Feature';
import './mixes.css';
import Envision from '../../assets/Envision_2014.mp3'
//mport EnvisionWav from '../../assets/Envision_2014.wav'
//import CoalesceWav from '../../assets/Coalesce NYE 2018 recorded 2.wav'
import Coalesce from '../../assets/Coalesce NYE 2018 recorded 2.mp3'
import TNF from '../../assets/Tipper and Friends Mix.mp3'
//import TNFWav from '../../assets/Tipper and Friends Mix.wav'
import CoalescePic from '../../assets/Coalesce1.png'
import EnvisionPic from '../../assets/Envision1.png'
import TNFPic from '../../assets/TNF.png'



const Mixes = () => {
  return (
    
    
    <div className="Cualli__mixes" id="Mixes">
      <div className="Cualli__mixes-text">
        <Feature title="Mixes"/>
      </div >
      <div className='Cualli__mixes-container'>
          <div className="Cualli_mixes-container__blocks">
            <img src={EnvisionPic}></img>
            <p>Ceremony Mix Envision 2014</p>
            <audio controls>
            <source src={Envision} type="audio/mpeg"/>
            your browser does not support the audio element.
            </audio> 
          </div>

          <div className="Cualli_mixes-container__blocks">
            <img src={CoalescePic}></img>
            <p>Coalesce NYE 2018</p>
            <audio controls>
            <source src={Coalesce} type="audio/mpeg"/>
            your browser does not support the audio element.
            </audio> 
          </div>

          <div className="Cualli_mixes-container__blocks">
            <img src={TNFPic}></img>
            <p>Tipper and Friends Mix 2016</p>
            <audio controls>
            <source src={TNF} type="audio/mpeg"/>
            your browser does not support the audio element.
            </audio>
          </div> 
      </div>
    

    </div>



  )
}

export default Mixes