import React from 'react'
import './merch.css';
import Feature from '../../components/feature/Feature';
import LP from "../../assets/Merch_lp-1.png"

const Merch = () => {
  return (
    <div className="Cualli__Merch section__margin" id="Merch">
      <div className="Cualli__Merch-title">
        <Feature title="Merch" />
      </div>
      <div className="Cualli__Merch-merch">
        <div className="Cualli__Merch-merch-container">
          <a href="https://cualli.bandcamp.com/album/tummy-fuzz">
            <img src={LP} alt="Tummy Fuzz LP"></img>
          </a>
          <a href="https://cualli.bandcamp.com/album/tummy-fuzz">
            <p>Tummy Fuzz Vinyl LP</p>
          </a>
          
        </div>
      </div>


    </div>
  )
}

export default Merch