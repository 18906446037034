import React,{ useRef, useEffect }  from 'react'
import upwardsVideo from '../../assets/Upwards_vid_emblem_2_1_VP9.webm'
import upwardsVid from '../../assets/Upwards_vid_emblem.mp4'
import './header.css';



const Possibility = function(props) {
    const videoRef = useRef(undefined);
    useEffect(() => {
        videoRef.current.defaultMuted = true;
    })
    return (
    <video className="gpt3__header-video" ref={videoRef} loop autoPlay muted playsInline>
    <source src={upwardsVideo} type="video/webm"></source>
    <source src={upwardsVid} type="video/mp4"></source>
  
    <p>Your browser doesn't support HTML5 video.</p>
  </video>);
}  


window.addEventListener('load', function(){
    var newVideo = document.getElementById('video');
    newVideo.addEventListener('ended', function() {
        this.currentTime = 0;
        this.play();
    }, false);

    newVideo.play();

});


function Header() {
  return (
    <div className="gpt3__header section__padding" id="home">
      {/* <div className="gpt3__header-content">
              <h1 className="gradient__text">Let&apos;s Build Something amazing with GPT-3 OpenAI</h1>
              <p>Yet bed any for travelling assistance indulgence unpleasing. Not thoughts all exercise blessing. Indulgence way everything joy alteration boisterous the attachment. Party we years to order allow asked of.</p>
              <div className="gpt3__header-content__input">
                <input type="email" placeholder="Your Email Address" />
                <button type="button">Get Started</button>
              </div>
      
              <div className="gpt3__header-content__people">
                <img src={people} alt="people" />
                <p>1,600 people requested access a visit in last 24 hours</p>
              </div>
            </div>
            <div className="gpt3__header-image">
            <img src={ai} />
            </div>*/}

      <div>
        {Possibility()}
      </div>

      <div className="gpt3__header-content">
        <div className="gpt3__header-credit">
          <p>Artwork: <a href="http://www.johnspeaker.com/">John Speaker</a> Animation: Cualli </p>
        </div>
      </div>

    </div>
  );
}

export default Header