import React from 'react'
import './footer.css';
const heart = "<3"
const Footer = () => {
  return (
    <div className="Cualli__Footer">
      <p>{heart}</p>
    </div>
  )
}

export default Footer