import React from 'react'
import './links.css';
import { facebook, instagram, soundCloud, spotify, youTube, bandCamp} from './imports';



const Links = () => (
  <div className="Cualli__links" id="links">
    <div>
      <a href="https://cualli.bandcamp.com/">
         <img alt="Bandcamp" src={bandCamp}></img>
      </a>
    </div>
    <div>
      <a href="https://open.spotify.com/artist/7qS1I7WtGLlODeSuRdpcHw">
         <img alt="Spotify" src={spotify}></img>
      </a>
    </div>
    <div> 
      <a href="https://www.youtube.com/c/CualliMusic">
         <img alt="Youtube" src={youTube}></img>
      </a>
    </div>
    <div>
      <a href="https://soundcloud.com/cualli">
         <img alt="Soundcloud" src={soundCloud}></img>
      </a>
    </div>
    <div>
      <a href="https://www.facebook.com/CualliMusic/">
         <img alt="Facebook" src={facebook}></img>
      </a>
    </div>
    <div>
      <a href="https://www.instagram.com/cuallimusic">
         <img alt="Instagram" src={instagram}></img>
      </a>
    </div>
  </div>
);

export default Links