import React from 'react'
import Feature from '../../components/feature/Feature';
import './videos.css';
import youTube from '../../assets/YouTube-2.png'
const YTlink = <a href="https://www.youtube.com/c/CualliMusic">here.</a>

const Videos = () => {
  return (
    
    
    <div className="Cualli__videos" id="Videos">
      
      <div className="Cualli__videos-text">
        <Feature title="Videos" />
      </div >

      <div className="Cualli__videos-container"><p><iframe 
      src="https://www.youtube.com/embed/K_siDfVyO2k" 
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      Zallowfullscreen></iframe></p>

      <p><iframe  
      src="https://www.youtube.com/embed/_q-4Gqx5Afg" 
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe></p>

      <p><iframe 
      src="https://www.youtube.com/embed/bH3-nY4feDk" 
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe></p>

      <p><iframe 
      src="https://www.youtube.com/embed/tDNwDT5Jq3s" 
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe></p>

      <p><iframe
      src="https://www.youtube.com/embed/j3xsiUMM7Tc" 
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe></p>

      <p><iframe 
      src="https://www.youtube.com/embed/Xgq-zDY22eY" 
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe></p>
      </div>

    </div>



  )
}

export default Videos