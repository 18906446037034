import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import './navbar.css';
import logo from '../../assets/cualli_logo.png'

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className='gpt3__navbar'>
      <div className= "gpt3__navbar-links">
        <div className= "gpt3__navbar-links_logo">
          <img src={logo} alt="logo" />
        </div>
        
      </div>
      {/*<div className="gpt3__navbar-links_container" >
          <p><a href="#links">Links</a></p>
          <p><a href="#tourDates">Tour Dates</a></p>
          <p><a href="Videos">Videos</a></p>
          <p><a href="#Mixes">Mixes</a></p>
          <p><a href="#Merch">Merch</a></p>
      </div>*/}
      {/*<div className="gpt3__navbar-sign">
        <p>Sign in</p>
        <button type="button">Sign up</button>
      </div>*/}
      <div className="gpt3__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
        <div className="gpt3__navbar-menu_container scale-up-center">
          <div className="gpt3__navbar-menu_container-links">
            <p><a href="#links">Links</a></p>
            <p><a href="#tourDates">Tour Dates</a></p>
            <p><a href="#Videos">Videos</a></p>
            <p><a href="#Mixes">Mixes</a></p>
            <p><a href="#Merch">Merch</a></p>
          </div>
          {/*<div className="gpt3__navbar-menu_container-links-sign">
            <p>Sign in</p>
            <button type="button">Sign up</button>
          </div>*/}
        </div>
        )}
      </div>
    </div>
  );
};


export default Navbar
