import React from 'react';

import { Mixes, Footer, Merch, Possibility, Videos, TourDates, Header } from './containers';
import {  Links, Navbar } from './components';
import './App.css';

const App = () => {
  return (
    <div className='App'>
        <div className="gradient__bg">
            <Navbar />
            <Header />
        </div>
        <Links />
        <TourDates />
        <Videos />
        <Possibility />
        <Mixes />
        <Merch />
        <Footer />     
    </div>
  )
}

export default App