import React from 'react';
import './feature.css';


const Feature = ({ title, text, text1, text2 }) => (
  <div className="gpt3__features-container__feature">
    <div className="gpt3__features-container__feature-title">
      <div />
      <h1>{title}</h1>
    </div>
    <div className="gpt3__features-container_feature-text">
      <p>{text}
      <a className="Cualli__text1">{text1}</a>
      </p>
    </div>
  </div>
);

export default Feature;