import React from 'react'
import Feature from '../../components/feature/Feature';
import './tourDates.css';

const TourDates = () => {
  return (
    <div className="Cualli__Tour-Dates section__margin" id="tourDates">
      <div className="Cualli__Tour-Dates-feature">
        <Feature title="Tour Dates" />
      </div>
      <div className="Cualli__Tour-Dates-container">
        <Feature title="April 23" text="Denver - Black Box" />
        <Feature title="May 27-29" text="Massachusetts - Nocturnal Funktion Festival" />
      </div>
    </div>
  )
}

export default TourDates